<template lang="pug">
div.video-box
  section.video.bg.red(:class="mode")
    video.video-js(ref="videoPlayer")
    div.controls.flex.padding._5
      div.grow.flex
        img.left(src="@/assets/ico/player/left.png" @click="_speed(0.5)")/
        img.play(:src="require(`@/assets/ico/player/${playerState?'paush':'play'}.png`)" @click="_play")/
        img.right(src="@/assets/ico/player/right.png" @click="_speed(2)")/
      div.progress
        div.bg.black
          div.now(:style="`width:${progress}%`")
</template>
<script>
import videojs from 'video.js';
export default {
  props: {
    src: {
      type: String,
      default: '', //https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.webm
    },
    mode: {
      type: String,
      default: 'video'
    }
  },
  watch:{
    src(url){
      if(url){
        let playerOptions = {
          controls: true,
          sources: [{src: this.src}],
          children: ['mediaLoader', 'posterImage'],
          // poster: '',
        }
        if(this.mode != 'video'){
          playerOptions.audioOnlyMode = true
        }
        this.player = videojs(this.$refs.videoPlayer, playerOptions)
        this.player.on('ended', ()=>{
          this.playerState = false
        })
        this.player.on('timeupdate', ()=>{
          let [pass, total] = [this.player.currentTime(), this.player.duration()]
          this.progress = Math.ceil(pass / total * 100) 
        })
      }
    },
  },
  data(){return{
    player: {},
    playerState: false, // 是否播放
    rate: 1, // 播放速度
    progress: 0,
  }},
  methods: {
    _play(){
      if(this.player.readyState()){
        this.player[['play', 'pause'][Number(this.playerState)]]()
        this.playerState = !this.playerState
      }
    },
    _speed(s){
      this.rate *= s / 1
      this.player.playbackRate(this.rate)
    }
  },
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/spacing.less";
@import "~@/assets/less/func.less";
.video-box{
  .video-js,video{ width: 100%; height: auto;}
  .audio .video-js{ .__wh(0, 0);}
  .video{
    img{
      .__rem(10, margin-left);
      &.play{ .__wh(50, 50) }
      &.left,&.right{ .__wh(30, 30) }
    }
    .progress{
      .__spacing(margin, 0, 20);
      width: 70%;
      .bg{
        .__spacing(padding, 3);
        .__rem(6, height);
        .__bg(black);
        .now{
          .__rem(6, height);
          .__bg(var(--red););
          width: 0;
          transition: width .5s;
        }
      } 
    }
  }
}
</style>
